<template>
  <div class="clearfix many-file-upload">
    <a-upload
      :disabled="disabled"
      :action="uploadImgUrl"
      :headers="headers"
      list-type="picture-card"
      :file-list="fileList"
      :data="uploadParams"
      :before-upload="beforeUpload"
      @change="handleChange"
    >
      <div v-if="fileList.length < maxFileCount">
        <a-icon type="plus" />
        <div class="ant-upload-text">上传</div>
      </div>
    </a-upload>
    <viewer :images="fileList" class="approval-images">
      <div v-for="(item, index) in fileList" :key="item.url" class="view-item">
        <!-- 正在上传中不存在删除 -->
        <i
          class="iconfont icon-quxiao view-item-delete"
          @click="handleDelete(index)"
          v-if="!item.url && disabled ? false : true"
        />
        <span v-if="item.status == 'uploading'"><a-icon type="sync" spin />上传中</span>
        <span @click.stop="handlePdfPreview(item.url)" v-else-if="item.url && item.url.indexOf('.pdf') > 0">
          PDF文档
        </span>
        <span
          @click.stop="handleOfficePreview(item.url)"
          v-else-if="item.url && (item.url.indexOf('.xls') > 0 || item.url.indexOf('.xlsx') > 0)"
        >
          Excel文档
        </span>
        <img v-else :src="item.url" height="100" />
      </div>
    </viewer>
  </div>
</template>

<script>
import storage from 'store'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { uuid } from '@/utils/util.js'

export default {
  name: 'ManyFileUpload',
  props: {
    initFileList: {
      type: Array,
      default: () => {}
    },
    folder: {
      type: String,
      default: 'expenses'
    },
    maxFileCount: {
      type: Number,
      default: 8
    }
  },
  components: {},
  data() {
    return {
      uploadImgUrl: process.env.VUE_APP_BASE_API + '/common/upload',
      headers: {
        Authorization: 'Bearer ' + storage.get(ACCESS_TOKEN)
      },
      fileList: [],
      uploadParams: {
        fileName: uuid(),
        type: 'aliyunoss',
        folder: this.folder
      },
      disabled: false,
      // 原始文件名列表
      originFileList: [],
      fileIndex: 0
    }
  },
  created() {
    this.setFileList(this.value)
  },
  mounted() {
    console.log('this.initFileList ***', this.initFileList)
    if (this.initFileList && this.initFileList.billImage) {
      this.setFileList(this.initFileList)
      this.$emit('billImageNum', this.originFileList.length)
    }
  },
  watch: {
    initFileList(newVal, oldVal) {
      console.log('initFileList', newVal)
      this.setFileList(newVal)
      this.$emit('billImageNum', this.originFileList.length)
    }
  },
  methods: {
    handleDelete(index) {
      console.log('ManyFileUplad-Delete')
      if (index < this.fileList.length) {
        const originFileName = this.fileList[index].name
        this.fileList.splice(index, 1)
        this.deleteOriginFileList(originFileName)
      }
      this.$emit('fileList', this.fileList)
      this.$emit('billImageNum', this.originFileList.length)
    },
    setFileList(d) {
      if (!d) {
        return
      }
      if (!d.billImage && !d.billImageUrl) {
        return
      }
      let billImageArr = []
      let billImageUrlArr = []
      this.fileList = []
      if (d.billImage) {
        billImageArr = d.billImage.split(',')
      }
      if (d.billImageUrl) {
        billImageUrlArr = d.billImageUrl
      }
      if (billImageUrlArr && Array.isArray(billImageUrlArr)) {
        billImageArr.forEach((p, idx) => {
          if (idx < billImageUrlArr.length) {
            this.fileList.push({
              uid: uuid(),
              name: p,
              fileName: p,
              url: billImageUrlArr[idx]
            })
            this.originFileList.push(p)
          }
        })
      }
      console.log('many-file-upload fileList', this.fileList)
    },
    handlePdfPreview(url) {
      var iWidth = 800 // 弹出窗口的宽度;
      var iHeight = 600 // 弹出窗口的高度;
      var iTop = (window.screen.height - 30 - iHeight) / 2 // 获得窗口的垂直位置;
      var iLeft = (window.screen.width - 10 - iWidth) / 2 // 获得窗口的水平位置;
      window.open(
        url,
        '',
        'height=' +
          iHeight +
          ',,innerHeight=' +
          iHeight +
          ',width=' +
          iWidth +
          ',innerWidth=' +
          iWidth +
          ',top=' +
          iTop +
          ',left=' +
          iLeft +
          ',toolbar=no,menubar=no,scrollbars=no,resizeable=no,location=no,status=no'
      )
    },
    handleOfficePreview(url) {
      const newUrl = 'http://view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent(url)
      this.handlePdfPreview(newUrl)
    },
    /**
     *
     * @param {Object} file 当前上传的文件对象
     * @param {*} fileList 已经上传的文件列表
     */
    beforeUpload(file) {
      console.log('beforeUpload', this.fileIndex)
      this.fileIndex++
      this.uploadParams.fileName = uuid()
      this.uploadParams.folder = this.folder
      // 文件类型(file.type)、大小限制(file.size)
      const fileSize = file.size / 1024 / 1024
      const isLt4M = fileSize <= 30
      console.log('beforeUpload', file)
      if (!isLt4M) {
        this.$warningEx('图片大小限制 30MB!')
        return false
      }
      if (file.type.indexOf('image') >= 0) {
        return new Promise(resolve => {
          // 图片压缩
          const reader = new FileReader()
          const img = new Image()
          reader.readAsDataURL(file)
          reader.onload = function(e) {
            img.src = e.target.result
          }

          img.onload = function() {
            const canvas = document.createElement('canvas')
            const context = canvas.getContext('2d')
            let originWidth = this.width
            let originHeight = this.height
            if (originWidth > 2400) {
              originWidth = 2400
              originHeight = this.height * (2400 / this.width)
            }
            canvas.width = originWidth
            canvas.height = originHeight

            context.clearRect(0, 0, originWidth, originHeight)
            context.drawImage(img, 0, 0, originWidth, originHeight)
            let quality = 1
            if (fileSize > 1) {
              quality = 0.8
            }
            canvas.toBlob(
              blob => {
                const imgFile = new File([blob], file.name, { type: file.type }) // 将blob对象转化为图片文件
                resolve(imgFile)
              },
              file.type,
              quality
            ) // file压缩的图片类型
          }
        })
      } else {
        if (this.isValidFileFormat(file.type)) {
          return true
        } else {
          this.$warningEx('上传文件格式不支持!')
          return false
        }
      }
    },
    isUploading(file) {
      const flag = file.status === 'uploading'
      // 通知调用方正在上传中
      this.$emit('onUploading', flag)
      return flag
    },
    // 判断是否为合法的文件格式
    isValidFileFormat(fileType) {
      if (fileType.indexOf('ms-excel') >= 0 || fileType.indexOf('pdf') >= 0 || fileType.indexOf('image') >= 0) {
        return true
      } else {
        return false
      }
    },
    handleChange({ fileList, file }) {
      if (file.type && !this.isValidFileFormat(file.type)) {
        return
      }
      console.log('handleChange')
      // 1.设置最新文件列表
      this.fileList = fileList
      this.disabled = this.isUploading(file)
      if (this.originFileList.indexOf(this.getFileName(file.name)) < 0) {
        this.originFileList.push(this.getFileName(file.name))
        // 记录上传文件的动作，但不代表上传成功
        this.$emit('billImageNum', this.originFileList.length)
      }
      // 2.当前新上传的文件处理
      if (file.status === 'done') {
        // 上传完成之后的处理
        if (file.response.code !== 200) {
          this.$warningEx('上传失败' + file.response.msg)
          this.deleteOriginFileList(this.getFileName(file.name))
          this.resetFileList()
          return false
        }

        // 3.先判断文件名是否已存在
        const fileName = file.response.fileName ? file.response.fileName.trim() : ''
        if (!fileName) {
          this.deleteOriginFileList(this.getFileName(file.name))
          this.$warningEx('上传失败->文件名为空！')
        }
        // 4.将当前新上传的文件加入到自定义的文件列表中
        const url = file.response.url ? file.response.url.trim() : ''
        if (!url) {
          this.deleteOriginFileList(this.getFileName(file.name))
          this.$warningEx('上传失败->上传文件URL为空！')
        }
        // 5. 判断文件名是否已存在
        if (fileName && url && !this.fileList.find(t => t.url === url)) {
          this.fileList.push({
            uid: uuid(),
            name: this.getFileName(file.name),
            fileName: fileName,
            url: url
          })
        }
        // 6.移除上传产生的原始数据
        this.resetFileList()
        // 7.上传成功之后回传给调用方UI
        this.$emit('fileList', this.fileList)
      }
    },
    deleteOriginFileList(name) {
      const k = this.originFileList.indexOf(name)
      if (k >= 0) {
        this.originFileList.splice(k, 1)
      }
    },
    resetFileList() {
      const tmpFileList = []
      this.fileList.forEach(p => {
        if (p.uid && p.fileName && p.url) {
          tmpFileList.push(p)
        }
      })
      this.fileList = tmpFileList
    },
    getFileName(name) {
      return this.fileIndex + name
    }
  }
}
</script>
<style lang="less">
.image-modal {
  z-index: 99999;
}
.approval-images {
  min-height: 110px;
  display: flex;
  flex-wrap: wrap;
  img,
  span {
    width: 100px;
    height: 100px;
    margin-right: 10px;
    margin-bottom: 10px;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    padding: 8px;
  }
  span {
    display: inline-block;
    text-align: center;
    line-height: 80px;
  }
}
.approval-images:first-child {
  margin-left: 120px;
}
.many-file-upload {
  display: flex;
  .ant-upload-list-picture-card-container {
    display: none;
  }
  .ant-upload-picture-card-wrapper {
    width: auto;
  }
  height: auto !important;
}
.view-item {
  position: relative;
  display: inline-block;
  .view-item-delete {
    position: absolute;
    top: -15px;
    right: 0;
    color: red;
    font-size: 24px;
    z-index: 9999999;
  }
}
</style>
