var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "clearfix many-file-upload" },
    [
      _c(
        "a-upload",
        {
          attrs: {
            disabled: _vm.disabled,
            action: _vm.uploadImgUrl,
            headers: _vm.headers,
            "list-type": "picture-card",
            "file-list": _vm.fileList,
            data: _vm.uploadParams,
            "before-upload": _vm.beforeUpload,
          },
          on: { change: _vm.handleChange },
        },
        [
          _vm.fileList.length < _vm.maxFileCount
            ? _c(
                "div",
                [
                  _c("a-icon", { attrs: { type: "plus" } }),
                  _c("div", { staticClass: "ant-upload-text" }, [
                    _vm._v("上传"),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "viewer",
        { staticClass: "approval-images", attrs: { images: _vm.fileList } },
        _vm._l(_vm.fileList, function (item, index) {
          return _c("div", { key: item.url, staticClass: "view-item" }, [
            (!item.url && _vm.disabled ? false : true)
              ? _c("i", {
                  staticClass: "iconfont icon-quxiao view-item-delete",
                  on: {
                    click: function ($event) {
                      return _vm.handleDelete(index)
                    },
                  },
                })
              : _vm._e(),
            item.status == "uploading"
              ? _c(
                  "span",
                  [
                    _c("a-icon", { attrs: { type: "sync", spin: "" } }),
                    _vm._v("上传中"),
                  ],
                  1
                )
              : item.url && item.url.indexOf(".pdf") > 0
              ? _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.handlePdfPreview(item.url)
                      },
                    },
                  },
                  [_vm._v(" PDF文档 ")]
                )
              : item.url &&
                (item.url.indexOf(".xls") > 0 || item.url.indexOf(".xlsx") > 0)
              ? _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.handleOfficePreview(item.url)
                      },
                    },
                  },
                  [_vm._v(" Excel文档 ")]
                )
              : _c("img", { attrs: { src: item.url, height: "100" } }),
          ])
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }